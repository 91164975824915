import React from 'react';
import classNames from 'classnames';
import {
  areIntervalsOverlapping,
  compareAsc,
  differenceInMinutes,
  eachHourOfInterval,
  format,
} from 'date-fns';
import styles from './AvailabilityTimeline.module.scss';

type AvailabilityTimelineVariant = 'default' | 'green' | 'gray' | 'selected';

interface AvailabilityTimelineProps {
  meetings: AvailabilityTimelineMeeting[];
  startTime: Date;
  endTime: Date;
  legend?: Record<AvailabilityTimelineVariant, string>;
}

export interface AvailabilityTimelineMeeting {
  start: Date;
  end: Date;
  title?: string;
  type?: string;
  variant?: AvailabilityTimelineVariant;
  icon?: React.ReactNode;
}

const AvailabilityTimeline = ({
  meetings,
  startTime,
  endTime,
  legend,
}: AvailabilityTimelineProps) => {
  if (compareAsc(startTime, endTime) === 1) {
    return null;
  }

  const timeSlots = eachHourOfInterval({
    start: startTime,
    end: new Date(endTime.getTime() - 60 * 60 * 1000),
  });
  const sortedMeetings = meetings.sort((a, b) => a.start.getTime() - b.start.getTime());
  const meetingRows = sortedMeetings
    .filter((item) => item.variant !== 'selected')
    .reduce((acc: AvailabilityTimelineMeeting[][], meeting) => {
      const notOverlappingIndex = acc.findIndex((group) => {
        return group.every((m) => {
          return !areIntervalsOverlapping(
            { start: m.start, end: m.end },
            { start: meeting.start, end: meeting.end },
          );
        });
      });

      if (notOverlappingIndex === -1) {
        acc.push([meeting]);
      } else {
        acc[notOverlappingIndex].push(meeting);
      }

      return acc;
    }, []);

  const calculateMeetingStyle = (meeting: AvailabilityTimelineMeeting, startTime: Date) => {
    const oneHourWidth = 6.25;
    const margin = 0.25;
    const blockHeight = 2.375;
    const oneMinuteWidth = oneHourWidth / 60;
    const blockWidth = differenceInMinutes(meeting.end, meeting.start) * oneMinuteWidth;
    const blockLeft = differenceInMinutes(meeting.start, startTime) * oneMinuteWidth;

    const meetingRowIndex = meetingRows.findIndex((group) => group.includes(meeting));
    const topOffset = meetingRowIndex * (blockHeight + margin);

    return {
      top: meeting.variant === 'selected' ? `${margin}rem` : `${margin + topOffset}rem`,
      height: meeting.variant === 'selected' ? `calc(100% - ${margin * 2}rem)` : undefined,
      width: `${blockWidth}rem`,
      left: `${blockLeft}rem`,
    };
  };

  const calculateGridStyle = () => {
    return {
      height: `${meetingRows.length * 2.75}rem`,
    };
  };

  return (
    <div className={styles.availabilityTimeline} data-testid="availability-timeline">
      <div className={styles.timeline}>
        <div className={styles.timeLabels}>
          {timeSlots.map((time) => (
            <div key={time.valueOf()} className={styles.timeLabel}>
              {format(time, 'HH:mm')}
            </div>
          ))}
        </div>
        <div className={styles.timeGridWrapper}>
          <div className={styles.timeGrid}>
            {timeSlots.map((time) => (
              <div key={time.valueOf()} className={styles.timeSlot} style={calculateGridStyle()} />
            ))}
          </div>
          {sortedMeetings.map((meeting) => (
            <div
              key={meeting.start.valueOf()}
              className={classNames(styles.meetingBlock, {
                [styles[`meetingBlock--${meeting.variant}`]]: meeting.variant,
              })}
              style={calculateMeetingStyle(meeting, startTime)}
            >
              {meeting.icon}
              {differenceInMinutes(meeting.end, meeting.start) > 30 && meeting.title}
            </div>
          ))}
        </div>
      </div>
      {legend && (
        <div className={styles.legend}>
          {Object.keys(legend).map((variant) => (
            <div key={variant} className={styles.legendItem}>
              <div className={classNames(styles.legendPill, styles[`legendPill--${variant}`])} />
              <span className={styles.legendTitle}>
                {legend[variant as AvailabilityTimelineVariant]}
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AvailabilityTimeline;
