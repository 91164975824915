import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { FormattedMessage, useIntl } from 'react-intl';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Banner,
  Button,
  defaultPhoneCodeList,
  Drawer,
  PhoneCodeDropdown,
  SearchDropdown,
  SearchDropdownMenuOption,
  TextField,
} from '@skiwo/components';
import { Formik } from 'formik';
import * as yup from 'yup';
import { emailRegex, phoneRegex } from '../../helpers/regexPatterns';
import useDebounce from '../../hooks/useDebounce';
import { useApi } from '../../providers/ApiProvider';
import { useToast } from '../../providers/ToastProvider/ToastProvider';
import translationKeys from '../../translations/translationKeys';
import { BrregSearchResult, EnterpriseSearchResult } from '../../types';
import styles from './CreateCustomerDrawer.module.scss';

interface Props {
  show: boolean;
  onClose: () => void;
}

interface FormValues {
  firstName: string;
  lastName: string;
  email: string;
  phoneCode: string;
  phoneNumber: string;
}

interface Address {
  city: string;
  line1: string;
  line2: string;
  postCode: string;
}

const CreateCustomerDrawer = (props: Props) => {
  const initialPhoneCode = defaultPhoneCodeList.find((phoneCode) => phoneCode.id === 47);
  const { show, onClose } = props;
  const intl = useIntl();
  const [createCustomerLoading, setCreateCustomerLoading] = useState(false);
  const [selectedPhoneCode, setSelectedPhoneCode] = useState<SearchDropdownMenuOption | undefined>(
    initialPhoneCode,
  );
  const [enterpriseResults, setEnterpriseResults] = useState<SearchDropdownMenuOption[]>([]);
  const [selectedEnterprise, setSelectedEnterprise] = useState<SearchDropdownMenuOption>();
  const [enterpriseInfo, setEnterpriseInfo] = useState<any>({});
  const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'success'>('idle');
  const [customerType, setCustomerType] = useState<string | undefined>();
  const [sendPassword, setSendPassword] = useState(true);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [userId, setUserId] = useState('');
  const [personId, setPersonId] = useState('');
  const [searchResults, setSearchResults] = useState<
    (EnterpriseSearchResult | BrregSearchResult)[]
  >([]);
  const api = useApi();
  const { showErrorToast } = useToast();

  const debounceEnterpriseSearch = useDebounce(300);

  const schema = yup.object().shape({
    firstName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.create_customer_drawer_first_name_label,
          }),
        },
      ),
    ),
    lastName: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.create_customer_drawer_last_name_label,
          }),
        },
      ),
    ),
    email: yup
      .string()
      .matches(
        emailRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_customer_drawer_email_label,
            }),
          },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_customer_drawer_email_label,
            }),
          },
        ),
      ),
    phoneCode: yup.string().required(
      intl.formatMessage(
        { id: translationKeys.form_error_required },
        {
          fieldName: intl.formatMessage({
            id: translationKeys.create_customer_drawer_country_code_label,
          }),
        },
      ),
    ),
    phoneNumber: yup
      .string()
      .matches(
        phoneRegex,
        intl.formatMessage(
          { id: translationKeys.form_error_not_valid },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_customer_drawer_phone_label,
            }),
          },
        ),
      )
      .required(
        intl.formatMessage(
          { id: translationKeys.form_error_required },
          {
            fieldName: intl.formatMessage({
              id: translationKeys.create_customer_drawer_phone_label,
            }),
          },
        ),
      ),
  });

  const handleSubmit = async (values: FormValues) => {
    let orgName = '';
    let orgNumber = '';

    if (enterpriseInfo && !enterpriseInfo.id) {
      orgName = enterpriseInfo.name || '';
      orgNumber = enterpriseInfo.orgNumber || '';
    }

    const payload = {
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      phoneCode: values.phoneCode,
      phoneNumber: values.phoneNumber,
      email: values.email,
      enterpriseId: enterpriseInfo?.id || null,
      createEnterprise: {
        orgName: orgName,
        orgNumber: orgNumber,
      },
      type: customerType,
    };

    const { data, error } = await api.createCustomer(
      {
        customer: payload,
        generateAndSendPassword: sendPassword,
      },
      setCreateCustomerLoading,
    );

    if (error) {
      showErrorToast(error);
    }

    if (data && data.customer) {
      setUserId(data.customer.uid);
      setPersonId(data.customer.personId);
      setFirstName(values.firstName);
      setLastName(values.lastName);
      setSubmissionStatus('success');
    }
  };

  const handleEnterpriseInfo = (orgNumber: number | string) => {
    const selectedEnterpriseInfo = searchResults.find(
      (enterprise) => enterprise.orgNumber === orgNumber,
    );
    setEnterpriseInfo(selectedEnterpriseInfo);
  };

  const getEnterpriseList = async (query: string, searchBrreg: boolean) => {
    const { data } = await api.searchEnterprises({
      query,
      search_brreg: searchBrreg,
    });

    if (data?.enterprises || data?.brregSearch) {
      // should be removed after BE changes
      const uniqueEnterprise = data?.enterprises.filter((obj, index, array) => {
        return array.findIndex((enterprise) => enterprise.orgNumber === obj.orgNumber) === index;
      });

      const allEnterprises = [...uniqueEnterprise, ...(data.brregSearch || [])];
      const enterpriseOptions = (allEnterprises || [])
        .filter((enterprise) => enterprise.orgNumber !== undefined)
        .map((enterprise) => {
          return {
            id: +enterprise.orgNumber,
            label: enterprise.name,
            key: enterprise.orgNumber,
            subtitle: `Org. nr. ${enterprise.orgNumber}`,
            group: intl.formatMessage({
              id:
                'id' in enterprise && enterprise.id !== undefined
                  ? translationKeys.create_customer_drawer_group_enterprise
                  : translationKeys.create_customer_drawer_group_brreg,
            }),
          };
        });

      setEnterpriseResults(enterpriseOptions);
      setSearchResults([...data.enterprises, ...data.brregSearch]);
    }
  };

  useEffect(() => {
    if (show) {
      getEnterpriseList('', false);
    }
  }, [show]);

  const formatAddress = (address: Address) => {
    const { city, line1, line2, postCode } = address;

    return ''.concat(
      line1 ? `${line1} ` : '',
      line2 ? `${line2} ` : '',
      postCode ? `${postCode} ` : '',
      city ? `${city}` : '',
    );
  };

  const getFullAddress = () => {
    return formatAddress(enterpriseInfo.address);
  };

  const getPostalAddress = () => {
    if (enterpriseInfo.contactAddresses && enterpriseInfo.contactAddresses[0]) {
      return formatAddress(enterpriseInfo.contactAddresses[0]);
    }

    return '-';
  };

  const goToProfile = () => {
    const railsGodmodeLink =
      process.env.REACT_APP_API_BASE_URL +
      `/radmin/person/${personId}/auth_link?uid=${userId}&action_name=login`;

    window.open(railsGodmodeLink, '_blank');
  };

  const clearForm = () => {
    setSelectedEnterprise(undefined);
    setSelectedPhoneCode(initialPhoneCode);
    setCustomerType(undefined);
    setSendPassword(true);
  };

  return (
    <Drawer
      show={show}
      onClose={() => {
        setSubmissionStatus('idle');
        clearForm();
        onClose();
      }}
      title={intl.formatMessage({
        id:
          submissionStatus === 'success'
            ? translationKeys.create_customer_drawer_success_title
            : translationKeys.create_customer_drawer_title,
      })}
    >
      {submissionStatus === 'success' ? (
        <div className={styles.succesContainer}>
          <div className={styles.blockBackground}>
            <div className={styles.innerBlockBackground}>
              <div className={styles.avatar}></div>
              <div className={styles.simulatedNames}>
                <span className={styles.simulatedFirstName}></span>
                <span className={styles.simulatedLastName}></span>
              </div>
              <FontAwesomeIcon icon={faCircleCheck} style={{ color: '#6ed08b' }} />
            </div>
          </div>
          <p className={styles.message}>
            <FormattedMessage
              id={translationKeys.create_customer_drawer_success_message}
              values={{
                firstName: <b>{firstName}</b>,
                lastName: <b>{lastName}</b>,
              }}
            />
          </p>
          <div className={styles.actions}>
            <Button
              size="large"
              variant="gray"
              data-testid="button-close"
              onClick={() => {
                setSubmissionStatus('idle');
                clearForm();
                onClose();
              }}
            >
              <FormattedMessage id={translationKeys.create_customer_drawer_success_close_button} />
            </Button>

            <Button
              size="large"
              data-testid="button-go-profile"
              onClick={() => {
                goToProfile();
              }}
            >
              <FormattedMessage
                id={translationKeys.create_customer_drawer_success_profile_button}
              />
            </Button>
          </div>
        </div>
      ) : (
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            phoneCode: initialPhoneCode?.key || '',
            phoneNumber: '',
          }}
        >
          {({
            handleSubmit,
            handleChange,
            handleBlur,
            handleReset,
            setFieldValue,
            values,
            touched,
            isValid,
            errors,
          }) => (
            <Form onSubmit={handleSubmit} className={styles.container} data-testid="new-customer">
              <div className={styles.nameInputs}>
                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.create_customer_drawer_first_name_placeholder,
                  })}
                  type="text"
                  label={intl.formatMessage({
                    id: translationKeys.create_customer_drawer_first_name_label,
                  })}
                  size="large"
                  name="firstName"
                  required
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.firstName}
                  errorText={touched.firstName ? errors.firstName : undefined}
                  data-testid="first-name-input"
                />

                <TextField
                  placeholder={intl.formatMessage({
                    id: translationKeys.create_customer_drawer_last_name_placeholder,
                  })}
                  type="text"
                  required
                  label={intl.formatMessage({
                    id: translationKeys.create_customer_drawer_last_name_label,
                  })}
                  size="large"
                  name="lastName"
                  onChange={(e) => {
                    handleChange(e);
                  }}
                  onBlur={handleBlur}
                  value={values.lastName}
                  errorText={touched.lastName ? errors.lastName : undefined}
                  data-testid="last-name-input"
                />
              </div>

              <TextField
                placeholder={intl.formatMessage({
                  id: translationKeys.create_customer_drawer_email_placeholder,
                })}
                type="email"
                label={intl.formatMessage({
                  id: translationKeys.create_customer_drawer_email_label,
                })}
                size="large"
                required
                name="email"
                onChange={(e) => {
                  handleChange(e);
                }}
                onBlur={handleBlur}
                value={values.email}
                errorText={touched.email ? errors.email : undefined}
                data-testid="email-input"
              />

              <div>
                <div className={styles.phoneInputs} data-testid="phone-code-dropdown">
                  <PhoneCodeDropdown
                    selected={selectedPhoneCode}
                    placeholder="+47"
                    label={intl.formatMessage({
                      id: translationKeys.create_customer_drawer_country_code_label,
                    })}
                    onChange={(phoneCodes) => {
                      if (phoneCodes && phoneCodes.length > 0 && phoneCodes[0].key) {
                        setSelectedPhoneCode(phoneCodes[0]);
                        setFieldValue('phoneCode', phoneCodes[0].key);
                      } else {
                        setSelectedPhoneCode(initialPhoneCode);
                        setFieldValue('phoneCode', initialPhoneCode);
                      }
                    }}
                    required
                    errorText={touched.phoneCode ? errors.phoneCode : undefined}
                  />

                  <TextField
                    placeholder={intl.formatMessage({
                      id: translationKeys.create_customer_drawer_phone_placeholder,
                    })}
                    type="text"
                    label={intl.formatMessage({
                      id: translationKeys.create_customer_drawer_phone_label,
                    })}
                    size="large"
                    name="phoneNumber"
                    required
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    value={values.phoneNumber}
                    errorText={touched.phoneNumber ? errors.phoneNumber : undefined}
                    data-testid="phone-number-input"
                  />
                </div>
              </div>

              <div>
                <p className={styles.customerTypeTitle}>
                  <FormattedMessage
                    id={translationKeys.create_customer_drawer_customer_type_title}
                  />
                </p>
                <div className={styles.customerType}>
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({
                      id: translationKeys.create_customer_drawer_customer_type_enterprise,
                    })}
                    name="customerType"
                    checked={customerType === 'enterprise'}
                    onChange={() => setCustomerType('enterprise')}
                    data-testid="customer-type-enterprise"
                  />
                  <Form.Check
                    type="radio"
                    label={intl.formatMessage({
                      id: translationKeys.create_customer_drawer_customer_type_private,
                    })}
                    name="customerType"
                    checked={customerType === 'private'}
                    onChange={() => setCustomerType('private')}
                    data-testid="customer-type-private"
                  />
                </div>
              </div>

              {customerType === 'enterprise' && (
                <div data-testid="enterprise-dropdown">
                  <SearchDropdown
                    options={enterpriseResults}
                    placeholder={intl.formatMessage({
                      id: translationKeys.create_customer_drawer_enterprise_placeholder,
                    })}
                    label={intl.formatMessage({
                      id: translationKeys.create_customer_drawer_enterprise_label,
                    })}
                    size="large"
                    search
                    grouped
                    required
                    onSearch={(query: string) => {
                      debounceEnterpriseSearch(() => {
                        getEnterpriseList(query, query !== '');
                      });
                    }}
                    onChange={(enterprise) => {
                      if (enterprise && enterprise.length > 0 && enterprise[0].key) {
                        setSelectedEnterprise(enterprise[0]);
                        handleEnterpriseInfo(enterprise[0].key);
                      } else {
                        setSelectedEnterprise(undefined);
                      }
                    }}
                  />
                </div>
              )}

              {selectedEnterprise && customerType === 'enterprise' && (
                <div>
                  <div className={styles.companyInformation}>
                    <h3 className={styles.title}>
                      <FormattedMessage
                        id={translationKeys.create_customer_drawer_company_info_title}
                      />
                    </h3>
                    <div className={styles.item}>
                      <span className={styles.subtitle}>
                        <FormattedMessage
                          id={translationKeys.create_customer_drawer_company_info_org_number}
                        />
                      </span>
                      <span className={styles.value}>{enterpriseInfo.orgNumber || '-'}</span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.subtitle}>
                        <FormattedMessage
                          id={translationKeys.create_customer_drawer_company_info_org_name}
                        />
                      </span>
                      <span className={styles.value}>{enterpriseInfo.name || '-'}</span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.subtitle}>
                        <FormattedMessage
                          id={translationKeys.create_customer_drawer_company_info_org_form}
                        />
                      </span>
                      <span className={styles.value}>{enterpriseInfo.organizationForm || '-'}</span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.subtitle}>
                        <FormattedMessage
                          id={translationKeys.create_customer_drawer_company_info_org_address}
                        />
                      </span>
                      <span className={styles.value} data-testid="full-address">
                        {getFullAddress() || '-'}
                      </span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.subtitle}>
                        <FormattedMessage
                          id={translationKeys.create_customer_drawer_company_info_org_county}
                        />
                      </span>
                      <span className={styles.value}>{enterpriseInfo.address.county || '-'}</span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.subtitle}>
                        <FormattedMessage
                          id={translationKeys.create_customer_drawer_company_info_postal_address}
                        />
                      </span>
                      <span className={styles.value}>{getPostalAddress() || ''}</span>
                    </div>
                    <div className={styles.item}>
                      <span className={styles.subtitle}>
                        <FormattedMessage
                          id={translationKeys.create_customer_drawer_company_info_org_website}
                        />
                      </span>
                      <span className={styles.value}>{enterpriseInfo.website || '-'}</span>
                    </div>
                  </div>

                  <Banner
                    variant="information"
                    text={intl.formatMessage({
                      id: translationKeys.create_customer_drawer_banner,
                    })}
                  />
                </div>
              )}

              <Form.Check
                type="checkbox"
                label={intl.formatMessage({
                  id: translationKeys.create_customer_drawer_password_checkbox,
                })}
                checked={sendPassword}
                onChange={(e) => setSendPassword(e.currentTarget.checked)}
                data-testid="check-box-password"
              />
              <div className={styles.actions}>
                <Button
                  size="large"
                  variant="gray"
                  data-testid="clear-button"
                  onClick={() => {
                    handleReset();
                    clearForm();
                  }}
                >
                  <FormattedMessage id={translationKeys.create_customer_drawer_clear_button} />
                </Button>

                <Button
                  size="large"
                  type="submit"
                  disabled={
                    !isValid ||
                    customerType === undefined ||
                    (customerType === 'enterprise' && !selectedEnterprise)
                  }
                  isLoading={createCustomerLoading}
                  data-testid="submit-button"
                >
                  <FormattedMessage id={translationKeys.create_customer_drawer_create_button} />
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </Drawer>
  );
};

export default CreateCustomerDrawer;
